// Home.js
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { decodeJwt } from "jose";
import Auth from "./Auth";

function Home() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(""); // Stocker le nom de l'utilisateur

  useEffect(() => {
    //document.title = "Cryptofanz.eu - Acceuil";
    const jwtToken = Cookies.get("session_token");
    alert(jwtToken);

    if (jwtToken) {
      try {
        const decodedToken = decodeJwt(jwtToken);
        if (decodedToken) {
          const { sub, exp } = decodedToken;
          const currentTime = Math.floor(Date.now() / 1000);
          if (exp > currentTime) {
            setIsLoggedIn(true);
            setUsername(sub);
          } else {
            Cookies.remove("session_token");
            setIsLoggedIn(false);
          }
        }
      } catch (error) {
        console.error("Erreur lors du décodage du token JWT", error);
        Cookies.remove("session_token");
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <div>
      <main>
        {/* Contenu principal de la page d'accueil (vide pour l'instant) */}
        {!isLoggedIn ? <Auth /> : <div></div>}
      </main>
    </div>
  );
}

export default Home;
